<template>
  <section class="m-active m-scroll" ref="scroll"  @scroll="handleScroll($event)" :style="{'background':active.theme_bgcolor}">
    <!-- 头部导航 -->
    <div class="header" >
      <div class="glass"></div>
      <p>
        <img @click="handleBack" src="@/assets/goods/icon_back_2@2x.png" alt="back">
      </p>
      <span></span>
      <span></span>
      <!-- <span @click="$router.push({path:'/zh/active/rule', query:{'rule_content': active.rule_content, 'rule_pic_url': active.rule_pic_url,'theme_bgcolor': active.theme_bgcolor }})">
        <span>活动规则</span>
      </span> -->
    </div>
    <div v-if="active == ''">
      <p style="width:100%;height:300px;background:#f1f1f1;"></p>
    </div>
    <div class="header-bg">
      <img :src="active.theme_pic_url" alt="">
    </div>
    <!-- 商品列表 -->
    <div>
      <div class="hot-title">热销单品</div>
      <goods-columns @cartClick="handleCart" :data="goodsList" :columns="active.item_style"></goods-columns>
      <p class="search-no" v-if='noMore'>暂无更多商品</p>  
    </div>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>

  </section>
</template>

<script>
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns-active.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import { activeList } from '@/api/zh/index.js'

import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      loading:false,
      skuModal:false,
      start:false,
      start_dom:null,
      dotAnimate:false,
      cartNum:0,
      scrollTop:false,
      goodsList: [],
      bgColor: '',
      goodsId: '',
      active: '',
      noMore: false,
      page:1
    }
  },
  created() {
    this.page = 1
    this.getActiveList()
  },
  mounted(){
    // localStorage.setItem('_back', '/')
  },

  methods:{
    // 获取活动商品列表
    getActiveList() {
      activeList(this.$route.query.id,{page: this.page}).then(res => {
        if(res) {
          this.active = res.data
          this.goodsList = this.goodsList.concat(res.data.goods_list.data)
        } 
        if (res.data.goods_list.data.length == 0) {
          this.noMore = true
        }
      }).finally(() => {
        this.loading  = false
      })
    },
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 0){
        if(!this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getActiveList()
        }
      }
      
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    }
  }
}
</script>

<style lang="less" scoped>
.hot-title {
  margin:10px 0 10px 16px;font-size:18px;font-weight:bold;color:#fff;
}
.search-no {
  line-height:80px;
  text-align:center;
  color:#888;
  font-size:14px;
}
@import './active.less';
</style>
